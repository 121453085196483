<template>
  <div class="">
    <v-card flat color="transparent">
      <v-card-title class="ml-0">
        <v-btn
          icon
          tile
          outlined
          text
          large
          class="mt-n2 ml-n4 rounded-lg"
          @click="$router.go(-1)"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>

        <h1 class="ml-4 text-title">
          Order {{ $route.params.id }}
        </h1>

        <v-btn icon tile outlined text class="mt-n2 ml-4 rounded-lg" large>
          <v-icon>
            mdi-file-multiple
          </v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <!-- <OrderViewModal /> -->
        <v-btn
          text
          outlined
          color="#47505e"
          class="white--text"
          large
          @click="showOrderModal = true"
        >
          <v-icon>mdi-folder-outline</v-icon>
          <span class="ml-2">View Details</span>
        </v-btn>
      </v-card-title>
    </v-card>

    <v-card :color="ant" height="120px" outlined>
      <v-card-title class="mt-2 ">
        {{ !getOrderDetail.topic  ? '( no topic )': getOrderDetail.topic }}
      </v-card-title>
      <v-card-text class="mt-n4 pt-0">
        <span> {{ getOrderDetail.language }} </span>
        <v-btn
          class="ml-4"
          :color="orderStatusColor(order.order_status)"
          x-small
          @click="routerPass(order)"
        >
          {{ getOrderDetail.order_status }}
        </v-btn>
        
        <v-btn icon tile v-if="order.order_status === 'closed'">
          <v-icon small>
            mdi-lock-outline
          </v-icon>
        </v-btn>
        <span class="ml-4">
          {{ formatDateMonth(getOrderDetail.final_date) }}
        </span>
        <span class="ml-4" :class="{'red--text': timeErrorStatus(getOrderDetail.final_date)}">
          {{ formatOrderDate(getOrderDetail.final_date) }}
        </span>
      </v-card-text>
    </v-card>

    <v-card flat class="mt-5" :color="dark" max-height="" v-if="getOrderDetail.order_status !== 'in-progress' && getOrderDetail.order_status !== 'completed' ">
      <!-- <v-row class="ma-0 p-0 mr-0">
        <v-col cols="8" class="py-0">
          <v-tabs background-color="transparent" class="mt-3">
            <v-tab key="tab1" :to="`/dashboard/order/${$route.params.id}/assign/bids`">ALl BIDS</v-tab>
            <v-tab key="tab2" :to="`/dashboard/order/${$route.params.id}/assign/shortlist`">
              SHORTLISTED</v-tab
            >
            <v-tab key="tab3" :to="`/dashboard/order/${$route.params.id}/assign/invitations`"
              >INVITATIONS</v-tab
            >
          </v-tabs>
          <v-divider class="mt-0" color="grey"></v-divider>
        </v-col>
        <v-col cols="4" class="px-0">
          <v-select
            solo
            single-line
            placeholder="Search"
            :background-color="ant"
            class="rounded-lg"
            :items="items"
            :value="items[0]"
          >
          </v-select>
        </v-col>
      </v-row> -->
      <router-view class=""></router-view>
    </v-card>

    <contract :order="getOrderDetail" class="mt-4"  v-if="getOrderDetail.order_status === 'in-progress' || getOrderDetail.order_status === 'completed' " />

    <filesUpload class="mt-5 mb-7" v-if="getOrderDetail.order_status === 'in-progress' || getOrderDetail.order_status === 'completed' " />
    <OrderViewModal :show="showOrderModal" @close="showOrderModal = false" />
  </div>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";
import validationMxn from '@/mixins/validation_mixin';
import timezoneMxn from '@/mixins/timezone_mixin';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: "assignBid",
  components: {
    OrderViewModal: () => import("@/modules/MyOrders/MyBids/orderViewModal"),
    contract: () => import("@/modules/writersDashboard/orderDetail/_components/contract"),
    filesUpload: () => import("@/components/filesUpload"),
  },
  mixins: [colorMxn, validationMxn, timezoneMxn],
  data() {
    return {
      showOrderModal: false,
      msg: "hey message",
      items: [
        "Writer's Rating",
        "Most Recent Bids",
        "Lowest bid",
        "Highest bid"
      ],
      order: {
        assinment_type: "Math Assignment",
        final_date: "Aug 11, 2020, 01:58 PM ",
        topic: "Machine learning",
        language: "English (US",
        order_status: 2
      }
    };
  },
  computed: {
    ...mapGetters(['getOrderDetail']),
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    ...mapMutations(['setMountUploadedFile', 'setOrderDetail']),
    async getOrders() {
      const payload = {
        page: 1,
      };
      const fullPayload = {
        params: payload,
        endpoint: `/order-detail/${this.$route.params.id}`
      };

      const response = await this.performGetActions(fullPayload);
      this.setMountUploadedFile(response.data[0].file);
      this.setOrderDetail(response.data[0]);
    },
  },
};
</script>

<style scoped>
.btn-border {
  border-radius: 4px;
  color: #fafafd;
  background-color: #232c3b;
  border: 1px solid #47505e;
}
a.v-tab {
  text-decoration: none;
}
</style>
